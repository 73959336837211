import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {useTranslation} from 'react-i18next';
import {FormControlLabel, Paper, Switch, Typography} from '@mui/material';
import './ToggleBoard.scss';

const ToggleBoard = ({elements = [], onChange, title, padding = '0', width = '30%'}) => {
    const [t] = useTranslation();
    const [_elements, set_Elements] = useState([...elements]);

    function processOnChange(index, checked) {
        const updatedElements = [..._elements];
        updatedElements[index].active = checked
        set_Elements(updatedElements);
        onChange(updatedElements);
    }

    return (<div id="toggle-board" key={Math.random() + "-key"} style={{padding, width}}>
        <Paper className="toggle-board-container" elevation={0}>
            <Typography className="toggle-board-title">
                {t(title)}
            </Typography>

            <div className="toggle-board-scrollable">
                {_elements?.slice(0, 10).map((element, index) => (<FormControlLabel
                    style={{width: 'fit-content', padding: '0px', margin: '2px 0px 2px -14px'}}
                    key={index}
                    label={<span className="toggle-board-label"
                                 style={{color: element.type ? '#9DADBC' : 'red'}}>{t(element.type)}</span>}
                    control={<Switch
                        className="switch"
                        checked={element.active}
                        onChange={(e) => processOnChange(index, e.target.checked)}
                    />}
                    className="toggle-board-item"
                />))}
            </div>
        </Paper>
    </div>);
};

ToggleBoard.propTypes = {
    elements: PropTypes.object.isRequired, onChange: PropTypes.func.isRequired, width: PropTypes.string,
};

export default ToggleBoard;
