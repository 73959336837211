import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {useTranslation} from 'react-i18next';
import {
    IconButton,
    Menu,
    MenuItem,
    Modal,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import MultiPosSwitch from "../MultyPosSwitch/MultiPosSwitch";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import {getCountryPrefix, getNodeContext} from "../../utils/utils";
import parsePhoneNumber from "libphonenumber-js";
import ToggleBoard from "../ToggleBoard/ToggleBoard";
import Button from "@mui/material/Button";
import _ from 'lodash';
import DialogActions from "@mui/material/DialogActions";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";

const notificationMethodOptions = ['sms', 'both', 'email'];
const MenuItemSX = {
    transition: 'background-color 0.3s ease', '&:hover': {
        backgroundColor: 'var(--border-color)'
    }
}
const footerStyle = {
    display: 'flex',
    justifyContent: 'flex-end',
    position: 'absolute',
    bottom: '0',
    width: 'calc(100% - 50px)',
    backgroundColor: 'var(--primary-bg)',
}
const ContactListEditor = ({contacts, defaultAlertSettings, onChange}) => {
    const {t} = useTranslation();
    const [alertSettings] = useState([...defaultAlertSettings])
    const [contactList, setContactList] = useState(contacts || []);
    const [editingCell, setEditingCell] = useState({row: null, column: null});
    const [editValue, setEditValue] = useState('');
    const [menuAnchor, setMenuAnchor] = useState(null);
    const [selectedRow, setSelectedRow] = useState(null);
    const [errorsMap, setErrorsMap] = useState({});
    const [personalNotificationsModalOpen, setPersonalNotificationsModalOpen] = useState(false);
    const [confModalOpen, setConfModalOpen] = useState(null)
    const handleCellClick = (rowIndex, column) => {
        if (rowIndex === editingCell.row && column === editingCell.column) {
            return;
        }
        if (isErrorState()) {
            return
        }
        setEditingCell({row: rowIndex, column});
        const currentValue = contactList[rowIndex][column];
        setEditValue(currentValue);
    };

    const handleEditChange = (e) => {
        const lastChar = e.target.value[e.target.value.length - 1];
        if (e.target.value.length > 1 && editingCell.column === 'phone' && isNaN(lastChar)) {
            return;
        }
        setEditValue(e.target.value);
    };

    const handleEditSave = (ee) => {
        debugger
        const updatedContacts = [...contactList];
        if (editingCell.column === 'notificationMethod') {
            updatedContacts[editingCell.row].notificationMethod = {
                sms: editValue === 'sms' || editValue === 'both', email: editValue === 'email' || editValue === 'both',
            };
        } else {
            if (editingCell.column === 'email' && !editValue.match(/^[\w-]+(\.[\w-]+)*@[\w-]+(\.[\w-]+)*(\.[a-z]{2,})$/)) {
                setErrorsMap({...errorsMap, [editingCell.row + '#' + editingCell.column]: "invalidEmailFormat"})
                ee.target.focus();
                return;
            } else if (editingCell.column === 'phone') {
                const phoneValidation = parsePhoneNumber(editValue)
                if (!phoneValidation || !phoneValidation.isValid()) {
                    setErrorsMap({...errorsMap, [editingCell.row + '#' + editingCell.column]: "invalidPhoneFormat"})
                    ee.target.focus();
                    return;
                }

            }
            setErrorsMap({...errorsMap, [editingCell.row + '#' + editingCell.column]: ""})
            if ( updatedContacts[editingCell.row][editingCell.column] === editValue){
                setEditingCell({row: null, column: null});
                setEditValue('');
                return
            }
            updatedContacts[editingCell.row][editingCell.column] = editValue;
        }
        setContactList(updatedContacts);
        setEditingCell({row: null, column: null});
        setEditValue('');
        onChange(updatedContacts);
    };

    function isErrorState() {
        return !!Object.values(errorsMap)?.filter(e => e)?.length
    }

    const handleEditCancel = () => {
        if (errorsMap[editingCell.row + editingCell.column]) {
            return
        }
        setEditingCell({row: null, column: null});
        setEditValue('');
    };

    const parseNotificationMethod = (notificationMethod) => {
        if (notificationMethod.sms && notificationMethod.email) {
            return 2;
        } else return notificationMethod.sms ? 1 : 3;
    };

    const handleAddContact = () => {
        if (contactList.length > 5) return;
        const newContact = {
            email: "contact@email.com",
            phone: '+' + getCountryPrefix(getNodeContext().country) + "0123456789",
            alerts: _.cloneDeep(alertSettings),
            notificationMethod: {sms: true, email: true},
        };

        setContactList((prevContacts) => [...prevContacts, newContact]);
        setEditingCell({row: contactList.length, column: 'phone'}); // Focus on the new email field
        setEditValue(newContact.phone);
    };
    const handleDeleteContact = (index) => {
        let errorsUpdatedMap = Object.keys(errorsMap).filter((key) => key.split('#')[0] != index)
        errorsUpdatedMap = errorsUpdatedMap.reduce((acc, key) => {
            acc[key] = errorsMap[key];
            return acc;
        }, {})
        if (selectedRow !== null) {
            const updatedContacts = contactList.filter((_, index) => index !== selectedRow);
            setContactList(updatedContacts);
            onChange(updatedContacts);
            setErrorsMap(errorsUpdatedMap)
        }
        handleMenuClose();
        setConfModalOpen(null)
    };
    const handleMenuClose = () => {
        setMenuAnchor(null);
        setSelectedRow(null);
    };

    const handleMenuOpen = (event, rowIndex) => {
        console.log("Nathan => rowIndex", rowIndex)
        setMenuAnchor(event.currentTarget);
        setSelectedRow(rowIndex);
    };

    let handleNotificationMethodChange = (_value, row) => {
        contactList[row].notificationMethod = _value === 1 ? {sms: true, email: false} : _value === 2 ? {
            sms: true, email: true
        } : {sms: false, email: true};
        setContactList([...contactList])
        onChange([...contactList])

    };

    function openPersonalNotificationsModal(contact) {
        setPersonalNotificationsModalOpen(contact);
        handleMenuClose();
    }

    function processChange(alertsSet) {
        onChange([...contactList])
    }

    function confirmationModal(index) {
        setConfModalOpen(index)
    }

    return (<TableContainer component={Paper} style={{
        position: 'relative',
        overflow: "hidden",
        backgroundColor: '#001333',
        maxHeight: "99%",
        width: '100%',
        fontSize: '1rem'
    }}>
        <Dialog open={null !== confModalOpen && confModalOpen >= 0}
                onClose={() => {
                    setConfModalOpen(null)
                }} aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                PaperProps={{
                    sx: {
                        backgroundColor: "#001333",
                        color: "#EAFBFF",
                        border: "1px solid #3D5484",
                        padding: "28px 24px 28px 24px",
                        borderRadius: "5px"
                    }
                }}
        >
            <DialogTitle id="alert-dialog-title">
                <div style={{textAlign: "center"}}>{t('areYouSureYouWantToDeleteThisUser')}
                </div>

            </DialogTitle>
            <DialogActions>
                <div className={"delete-actions-row"}>
                    <Button onClick={() => {
                        setConfModalOpen(null)
                    }}
                            size="small"
                            style={{
                                color: "#EAFBFF", fontSize: "14px", fontFamily: "Work Sans", textTransform: "none"
                            }}>
                        <b>{t('cancel')}</b>
                    </Button>
                    <Button onClick={() => handleDeleteContact(confModalOpen)}
                            variant="outlined"
                            style={{
                                color: "#FF4D4D",
                                borderColor: "#FF4D4D",
                                fontSize: "14px",
                                fontFamily: "Work Sans",
                                textTransform: "none"
                            }}>
                        <b>{t('delete')}</b>
                    </Button>

                </div>
            </DialogActions>
        </Dialog>
        <Modal open={Boolean(personalNotificationsModalOpen)} onClose={() => setPersonalNotificationsModalOpen(null)}>
            <div style={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                padding: '25px',
                transform: 'translate(-50%, -50%)',
                width: '20%',
                height: '25%',
                backgroundColor: 'var(--primary-bg)',
                border: '1px solid var(--border-color)',
            }}>
                <div style={{
                    fontWeight: 600, fontSize: '1.2rem', color: "var(--title-color)"
                }}>{t('personalNotificationsSettings')}</div>
                <ToggleBoard width={'100%'} elements={personalNotificationsModalOpen?.alerts || []}
                             onChange={processChange}/>
                <div style={footerStyle}>
                    <Button onClick={() => setPersonalNotificationsModalOpen(null)}>{t('close')}</Button>
                </div>
            </div>
        </Modal>
        <div className={'title-s '}
             style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '0 5px'}}>
            <span>{t('contacts')}</span>
            <IconButton disabled={contactList.length > 5 || isErrorState(editingCell)} onClick={handleAddContact}
                        sx={{color: 'var(--primary-text)'}}>
                {contactList.length <= 5 && <AddIcon/>}
                <span style={{
                    marginLeft: '5px',
                    fontSize: '1rem',
                    color: contactList.length <= 5 ? '#EAFBFF' : 'var(--primary-text)'
                }}>{t('addContact')}</span>
            </IconButton>
        </div>
        <Table fullWidth>
            <TableHead fullWidth sx={{ width: "100%"}}>
                <TableRow sx={{width: '100%'}}>
                    <TableCell sx={{
                        width: Math.floor((100 - 7) / 3) + "%",
                        color: '#EAFBFF',
                        borderColor: '#3D5484',
                        padding: '6px',
                        fontSize: '1rem'
                    }}>
                        {t('Email')}
                    </TableCell>
                    <TableCell sx={{
                        width: Math.floor((100 - 7) / 3) + "%",
                        color: '#EAFBFF',
                        borderColor: '#3D5484',
                        padding: '6px',
                        fontSize: '1rem'
                    }}>
                        {t('Phone')}
                    </TableCell>
                    <TableCell sx={{
                        width: Math.floor((100 - 7) / 3) + "%",
                        color: '#EAFBFF',
                        borderColor: '#3D5484',
                        padding: '6px',
                        fontSize: '1rem'
                    }}>
                        {t('notificationMethod')}
                    </TableCell>
                    <TableCell sx={{
                        width: '7%', color: 'var(--primary-text)', borderColor: '#3D5484', padding: '0 6px', fontSize: '1rem'
                    }}/>
                </TableRow>

                {contactList.length && <TableRow>
                    <TableCell sx={{
                        width: Math.floor((100 - 7) / 3) + "%",
                        color: 'var(--primary-text)',
                        borderColor: '#001333',
                        padding: '0 6px',
                        fontSize: '1rem'
                    }}>

                    </TableCell>
                    <TableCell sx={{
                        width: Math.floor((100 - 7) / 3) + "%",
                        color: 'var(--primary-text)',
                        borderColor: '#001333',
                        padding: '0 6px',
                        fontSize: '1rem'
                    }}>

                    </TableCell>
                    <TableCell sx={{
                        width: Math.floor((100 - 7) / 3) + "%",
                        color: 'var(--primary-text)',
                        borderColor: '#001333',
                        padding: '3px 6px',
                        fontSize: '0.8rem'
                    }}>
                        {t('sms/both/email')}
                    </TableCell>
                    <TableCell sx={{
                        width: '7%', color: 'var(--primary-text)', borderColor: '#001333', padding: '0 6px', fontSize: '1rem'
                    }}/>
                </TableRow>}
            </TableHead>
            <TableBody>
                {contactList.map((contact, rowIndex) => (<TableRow key={rowIndex}>
                    {['email', 'phone', 'notificationMethod'].map((column) => (<TableCell
                        key={column}
                        onClick={column === 'notificationMethod' ? () => {
                        } : () => handleCellClick(rowIndex, column)}
                        sx={{
                            color: 'var(--primary-text)',
                            cursor:column === 'notificationMethod' ? '': 'pointer',
                            borderColor: 'var(--primary-bg)',
                            padding: '6px',
                            fontSize: '1rem',
                        }}
                    >
                        {editingCell.row === rowIndex && editingCell.column === column ? (column === 'notificationMethod' ? (
                            <MultiPosSwitch
                                disabled={isErrorState()}
                                options={notificationMethodOptions}
                                onChange={(e) => handleNotificationMethodChange(e, rowIndex)}
                                selected={parseNotificationMethod(contact.notificationMethod)}
                            />) : (<TextField
                            value={editValue}
                            onChange={handleEditChange}
                            onBlur={handleEditSave}
                            error={errorsMap[rowIndex + '#' + column]}
                            helperText={t(errorsMap[rowIndex + '#' + column])}
                            sx={{
                                '& .MuiInput-underline:after': {
                                    borderBottomColor: 'var(--border-color)',
                                }
                            }}
                            InputProps={{
                                sx: {
                                    color: '#92c4f3', height: '25px', fontSize: '1rem'
                                }
                            }}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') handleEditSave(e);
                                if (e.key === 'Escape') handleEditCancel(e);
                            }}
                            autoFocus
                            variant="standard"
                        />)) : column === 'notificationMethod' ? (<MultiPosSwitch
                            options={notificationMethodOptions}
                            disabled={isErrorState()}
                            onChange={(e) => handleNotificationMethodChange(e, rowIndex)}
                            selected={parseNotificationMethod(contact.notificationMethod)}
                        />) : (<div
                            className={`value ${errorsMap[rowIndex + '#' + column] ? "error" : ""}`}>{contact[column]}</div>)}
                    </TableCell>))}
                    <TableCell sx={{padding: '0 6px', borderColor: '#001333'}}>
                        <IconButton onClick={(event) => handleMenuOpen(event, rowIndex)}>
                            <MoreVertIcon sx={{color: 'var(--title-color)'}}/>
                        </IconButton>
                        <Menu
                            key={rowIndex}
                            anchorEl={menuAnchor}
                            open={Boolean(menuAnchor)}
                            onClose={handleMenuClose}
                            sx={{
                                '& .MuiPaper-root': {
                                    backgroundColor: '#001333',
                                    borderRadius: '4px',
                                    color: 'var(--title-color)',
                                    border: '1px solid #3D5484',
                                    mt: '-40px',
                                }
                            }}
                        >
                            <MenuItem disabled={isErrorState(editingCell)}
                                      key={rowIndex + "-" + contact.email}
                                      sx={MenuItemSX}
                                      onClick={() => openPersonalNotificationsModal(contactList[selectedRow])}>{t('personalNotifications')}</MenuItem>
                            <MenuItem disabled={isErrorState(editingCell) && selectedRow !== rowIndex}
                                      key={rowIndex + "-" + contact.phone}
                                      sx={MenuItemSX}
                                      onClick={() => confirmationModal(rowIndex)}>{t('delete')}</MenuItem>
                        </Menu>
                    </TableCell>
                </TableRow>))}
            </TableBody>
        </Table>
    </TableContainer>);
};

ContactListEditor.propTypes = {
    contacts: PropTypes.arrayOf(PropTypes.shape({
        email: PropTypes.string.isRequired,
        phone: PropTypes.string.isRequired,
        notificationMethod: PropTypes.shape({
            sms: PropTypes.bool.isRequired,
            email: PropTypes.bool.isRequired,
        }).isRequired,
    })).isRequired,
    onChange: PropTypes.func.isRequired,
    defaultAlertSettings: PropTypes.arrayOf(PropTypes.shape({
        type: PropTypes.string.isRequired,
        active: PropTypes.bool.isRequired
    })).isRequired
};

export default ContactListEditor;
