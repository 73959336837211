import './SiteSettingsDrawer.scss'
import {useTranslation} from "react-i18next";
import {Box, Drawer, Tab, Tabs} from "@mui/material";
import {useEffect, useState} from "react";
import {fetchSite} from "../../../api/api";
import {getNodeContext} from "../../../utils/utils";
import PropTypes from "prop-types";
import CloseIcon from '@mui/icons-material/Close';
import SiteSettings from "./SiteSettings/SiteSettings";
import AlertsSettingsTab from "./AlertsSettingsTab/AlertsSettingsTab";
import ManagerNotificationsTab from "./ManagerNotificationsTab/ManagerNotificationsTab";
import LifeguardsTab from "./LifeguardTab/LifeguardTab";
function CustomTabPanel(props) {
    const {children, value, index, ...other} = props;
    return (
        <div
            className={'panel'}
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{p: 1}}>
                    <div>{children}</div>
                </Box>
            )}
        </div>
    );
}

CustomTabPanel.propTypes = {
    index: PropTypes.number,
    children: PropTypes.node
};
const SiteSettingsDrawer = props => {

    const [t] = useTranslation();
    const [site, setSite] = useState({settings: {contact: {}}})
    const [selectedTab, setSelectedTab] = useState(0)
    const [title, setTitle] = useState()
    const [weeklyReportSubscription, setWeeklyReportSubscription] = useState()
    const [email, setEmail] = useState()
    const [phone, setPhone] = useState()
    const [titleErr, setTitleErr] = useState('')
    const siteId = getNodeContext().siteId
    async function reFetchSite (){
       return fetchSite(siteId).then(_site => {
            setSite(_site)
            setWeeklyReportSubscription(_site.settings?.weeklyReportSubscription|| false)
            setTitle(_site.title)
            setEmail(_site.settings?.contact?.email || null)
            setPhone(_site.settings?.contact?.phone || null)
            return _site
        })
    }
    useEffect(() => {
       reFetchSite()
    }, [props.openState])
    console.log("Nathan => site managerNotificationsFeature", site.settings.managerNotificationsFeature)
const tabs = [t('general'), t('lifeguards')]
    function renderTabsContent() {
        if (getNodeContext().isFeatureAlertSilence){
            tabs.splice(1,0,t('alerts'))
            if (site.settings.managerNotificationsFeature ) {
                tabs.push(t('managerNotifications'))
            }
            return tabs.map(tab => {
                return <Tab sx={{paddingLeft:'32px ',textTransform: "none", "&:Mui-selected": {color: "#EAFBFF"}}} label={tab} id={'tab-' + tab}
                            aria-controls={'aria-tab-' + tab}/>
            });
        } else{
            tabs.splice(1,0,t(''))
            if (site.settings.managerNotificationsFeature) {
                tabs.push(t('managerNotifications'))
            }
            return tabs.map((tab,index) => {
                if(index === 1){
                    return <></>

                }else return <Tab sx={{paddingLeft:'32px ',textTransform: "none", "&:Mui-selected": {color: "#EAFBFF"}}} label={tab} id={'tab-' + tab}
                            aria-controls={'aria-tab-' + tab}/>
            });
        }
    }

    function handleSettingsClose() {
        props.handleSettingsClose()
    }

    return (
        <Drawer id={'site-settings-drawer'}
                anchor={'right'}
                open={props.openState}
                onClose={props.handleSettingsClose}
                sx={{".MuiDrawer-paper": {borderLeft:'solid 1px #3D5484',backgroundColor: '#001333'}}}>
            <div className={'title'}>{t('settings')}
            <CloseIcon className={'exit-button'} onClick={handleSettingsClose}/>
            </div>
            <Tabs TabIndicatorProps={{style:{backgroundColor:'#001333'}}}
                value={selectedTab}
                  sx={{
                      borderBottom:'solid 1px #3D5484',
                      ".MuiTab-textColorPrimary": {
                          color: `#9DADBC`, fontWeight: 400,fontSize:'1rem'
                      },
                      ".Mui-selected": {
                          color: `#EAFBFF`, fontWeight: 500
                      }, backgroundColor: "#001333"
                  }}
                  onChange={(ev, value) => {
                      setSelectedTab(value)
                  }}>
                {renderTabsContent()}
            </Tabs>

            <CustomTabPanel value={selectedTab} index={0}>
                <div className={'settings-paper'}>
                    <SiteSettings activateSnackbar={props.activateSnackbar}
                                  handleSettingsClose={props.handleSettingsClose}/>
                </div>
            </CustomTabPanel>
            { getNodeContext().isFeatureAlertSilence &&
            <CustomTabPanel value={selectedTab} index={1}>
                <div className={'settings-paper'}>
                    <AlertsSettingsTab activateSnackbar={props.activateSnackbar} fetchSite={fetchSite} site={site}/>
                </div>
            </CustomTabPanel>
            }
            <CustomTabPanel value={selectedTab} index={2}>
                <div className={'settings-paper'}>
                    <LifeguardsTab site={site}/>
                </div>
            </CustomTabPanel>
            {!!site.settings.managerNotificationsFeature && <CustomTabPanel value={selectedTab} index={3}>
                <div className={'settings-paper'}>
                    <ManagerNotificationsTab fetchSite={reFetchSite} activateSnackbar={props.activateSnackbar}
                                             site={site}/>
                </div>
            </CustomTabPanel>}
        </Drawer>
    )
}
SiteSettingsDrawer.propTypes = {
    openState: PropTypes.bool.isRequired,
    activateSnackbar: PropTypes.func.isRequired, //activateSnackbar(message, errorState)
    handleSettingsClose: PropTypes.func.isRequired
}
export default SiteSettingsDrawer


