import {Box, Slider} from "@mui/material";
import PropTypes from "prop-types";
import {useTranslation} from "react-i18next";
import {logout} from "../../api/api";

const MultiPosSwitch = (props) => {
    const[t] = useTranslation()
    function processSelectedValue(v) {
        props.onChange(v.target.value)
    }

    return (<div>
        <Box key={Math.random+'-key'} sx={{ width: '109px',height:'20px',padding:'0 5px', display:'flex'}}>
            <Slider disabled={props.disabled}
                    sx={{
                        '& .MuiSlider-thumb': {
                            color: props.disabled ? "var(--primary-text)" : "var(--switch-active)"
                        },
                        '& .MuiSlider-track': {
                            color: props.disabled ?  "var(--border-color-dis)" : "var(--border-color)"
                        },
                        '& .MuiSlider-rail': {
                            color: props.disabled ? "var(--border-color-dis)" : "var(--border-color)",
                            opacity: 1
                        }
                    }}
                defaultValue={props.selected}
                step={1}
                size={'small'}
                onChange={processSelectedValue}
                max={props.options.length}
                min={1}
                valueLabelDisplay="auto"
                valueLabelFormat={(v) =>t( props.options[v-1])}
            />
        </Box>
    </div>)
}

MultiPosSwitch.propTypes = {
    options: PropTypes.array.isRequired,
    selected: PropTypes.number.isRequired,
    onChange: PropTypes.func.isRequired,
    disabled: PropTypes.bool
}
export default MultiPosSwitch