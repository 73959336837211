import { useState} from "react";
import './ManagerNotificationsTab.scss';
import PropTypes from "prop-types";
import OpeningHoursTable from "../../../OpeningHoursTable/OpeningHoursTable";
import {useTranslation} from "react-i18next";
import ToggleBoard from "../../../ToggleBoard/ToggleBoard";
import ContactListEditor from "../../../ContactListEditor/ContactListEditor";
import {patchSite} from "../../../../api/api";
import {showSnackbar} from "../../../../utils/SnackbarUtils";

const ManagerNotificationsTab = ({site, fetchSite}) => {
    const [_site,setSite] = useState(site)
    const [openingHours, setOpeningHours] = useState(_site.settings?.openingHours || [])
    const [alertSettings,setAlertSettings] = useState(_site.settings?.managerNotifications?.alerts || [])
    const [contacts,setContacts] = useState(_site.settings?.managerNotifications?.contacts || [])
    const [t] = useTranslation()
    const [weekStartsOnSunday] = useState(false)
    const reFetchSite = () => {
       fetchSite(_site.id).then(newSite => {
           setSite(newSite)
       })
    }
    const handleOpeningHoursChange = (_openingHours) => {
        setOpeningHours(_openingHours)
        patchSite(_site.id, {settings: {openingHours: _openingHours}}).then(res => {
            showSnackbar(t("openingHoursSaved"), 'success')
            reFetchSite()
        }).catch(err => {
            console.log("Error in save opening hours", err)
            showSnackbar(t("openingHoursFailed"), 'error')
        })
    }

    function handleContactChange(_contacts) {
        setContacts(_contacts)
        patchSite(_site.id, {settings: {managerNotifications: {contacts: _contacts, alerts: alertSettings}}}).then(res => {
            res === 200? showSnackbar(t("contactsSaved"), 'success'): showSnackbar(t("contactsSaveFailed"), 'error')
            reFetchSite()
        }).catch(err => {
            console.log("Error in save contacts", err)
            showSnackbar(t("contactsSaveFailed"), 'error')
        })
    }

    function handleAlertsSettingsChange(newSettings) {
        setAlertSettings(newSettings)
        patchSite(_site.id, {settings: {managerNotifications: {contacts, alerts: newSettings}}}).then(res => {
            if (res === 200){
                showSnackbar(t("alertSettingsSaved"), 'success')
                reFetchSite()
            }else {
                showSnackbar(t("alertSettingsFailed"), 'error')
            }
        }).catch(err => {
            console.log("Error in save alerts settings", err)
            showSnackbar(t("alertSettingsFailed"), 'error')
        })
    }

    return (<div id={'manager-notifications-tab'}>

        <div className={'contact-section'}>
            <ContactListEditor contacts={contacts} onChange={handleContactChange} defaultAlertSettings={alertSettings}/>
        </div>
        <div className={'opening-hours-section'}>
                <OpeningHoursTable weekStartsOnSunday={weekStartsOnSunday} initialHours={openingHours}
                                   handleOpeningHoursChange={handleOpeningHoursChange}/>
                <div className={'vert-separator'}/>
                <ToggleBoard width={'40%'}
                             padding={'0 0 0 25px'}
                             title={'defaultNotificationSettings'}
                    elements={[...alertSettings]}
                    onChange={handleAlertsSettingsChange}/>
        </div>
    </div>)
}
ManagerNotificationsTab.propTypes = {
    site: PropTypes.object.isRequired,
    fetchSite: PropTypes.func.isRequired
}
export default ManagerNotificationsTab


